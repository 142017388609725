import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

const Reports = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const sessionToken = localStorage.getItem("sessiontoken");
      const response = await fetch(
        "https://s37ovkglhf264t6e3pqsgidwtu0nalvo.lambda-url.us-east-1.on.aws/report",
        {
          method: "GET", // Changed to GET as we're not sending a body anymore
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionToken}`, // Send token in Authorization header
          },
        }
      );
      const result = await response.json();

      if (result.error) {
        setError(true);
      } else {
        setData(result.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    }
  };

  return (
    <div>
      {error ? (
        <div>An error occurred while fetching data.</div>
      ) : (
        <div>
          <h2>Reports</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Campaign</th>
                <th>Total Visits</th>
                <th>Unique Visitors</th>
                <th>Total Templates</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.campaign}</td>
                  <td>{item.times_used}</td>
                  <td>{item.unique_users}</td>
                  <td>{item.total_templates}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default Reports;
