import React, { useEffect, useRef } from 'react';

const GoogleSignInButton = ({ handleCredentialResponse }) => {
  const googleButtonRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      const google = window.google;
      google.accounts.id.initialize({
        client_id: '57035674552-a2nvskt96usg800h7k1hbnhdu52e4fce.apps.googleusercontent.com', // Your actual client ID
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        googleButtonRef.current,
        { theme: 'outline', size: 'large' } // Customize the button appearance if desired
      );
    }
  }, [handleCredentialResponse]);

  return <div ref={googleButtonRef} />;
};

export { GoogleSignInButton };  // Export GoogleSignInButton for use in other components
