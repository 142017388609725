import React, { useState } from "react";

const CreateCampaign = () => {
  const [formData, setFormData] = useState({
    campaignName: "",
    emailList: "",
    talkingPoints: Array(5).fill(""),
    characterCounts: Array(5).fill(""),
    randomTalkingPoints: Array(3).fill(""),
    greetings: Array(5).fill(""),
  });
  const [loading, setLoading] = useState(false);
  const [preApproving, setPreApproving] = useState(false);
  const [results, setResults] = useState(null);
  const [showPreApprove, setShowPreApprove] = useState(false);
  const [selectedUuids, setSelectedUuids] = useState([]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    // Extracting the non-digit prefix and the number
    const match = id.match(/(\D+)(\d+)$/);
    if (match) {
      const fieldBase = match[1]; // Append 's' to match the state key format
      const index = parseInt(match[2], 10) - 1; // Correct index calculation

      if (formData[fieldBase] && Array.isArray(formData[fieldBase])) {
        const updatedArray = [...formData[fieldBase]];
        updatedArray[index] = value;
        setFormData({ ...formData, [fieldBase]: updatedArray });
      } else {
        console.warn(
          "Field is either not an array or undefined in formData:",
          fieldBase,
          formData
        );
      }
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const sessionToken = localStorage.getItem("sessiontoken");

    try {
      const response = await fetch(
        "https://s37ovkglhf264t6e3pqsgidwtu0nalvo.lambda-url.us-east-1.on.aws/generate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionToken,
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      setLoading(false);

      if (data.error) {
        alert(`Error: ${data.error}`);
      } else {
        setResults(data);
        setShowPreApprove(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      alert("Some error occurred.");
    }
  };

  const handleCheckboxChange = (uuid, isChecked) => {
    setSelectedUuids((prevUuids) => {
      if (isChecked) {
        // Add the uuid to the array if it is checked
        return [...prevUuids, uuid];
      } else {
        // Remove the uuid from the array if it is unchecked
        return prevUuids.filter((id) => id !== uuid);
      }
    });
  };

  const handlePreApprove = async (e) => {
    e.preventDefault();
    setPreApproving(true);
    const sessionToken = localStorage.getItem("sessiontoken");
    try {
      const response = await fetch(
        "https://s37ovkglhf264t6e3pqsgidwtu0nalvo.lambda-url.us-east-1.on.aws/preapprove",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionToken,
            // Add other necessary headers here
          },
          body: JSON.stringify({ uuids: selectedUuids }), // Send selected UUIDs in the request body
        }
      );

      const data = await response.json();
      if (!data.error) {
        alert(data.message);
      } else {
        alert("Error: " + data.message);
      }
    } catch (error) {
      console.error("Failed to pre approve:", error);
      alert("Failed to perform operation.");
    } finally {
      setPreApproving(false);
    }
  };

  return (
    <div>
      <div
        id="formContainer"
        style={{ display: "block", textAlign: "left" }}
        className="col-md-6 col-10"
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="campaignName">
              <b>Campaign Name</b> (provide something like
              "uga-email-president")
            </label>
            <input
              type="text"
              className="form-control"
              id="campaignName"
              placeholder="Enter campaign name"
              value={formData.campaignName}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="emailList">
              <b>Provide comma separated list of recipient email ids.</b>
            </label>
            <input
              type="text"
              className="form-control"
              id="emailList"
              placeholder="Enter recipient email ids"
              value={formData.emailList}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>
              <b>Talking Points</b> (All these will be an input to the AI ML
              model to generate random emails. Write each talking point clearly.
              You may leave some of them blank.)
            </label>
            {formData.talkingPoints.map((tp, index) => (
              <input
                key={index}
                type="text"
                className="form-control mb-2"
                id={`talkingPoints${index + 1}`}
                placeholder={`Talking Point ${index + 1}`}
                value={tp}
                onChange={handleChange}
              />
            ))}
          </div>

          <div className="form-group">
            <label>
              <b>Number of Characters in the email</b> (Specify the number of
              characters. The model will select 1 of these values at random and
              generate an email.)
            </label>
            {formData.characterCounts.map((ch, index) => (
              <input
                key={index}
                type="text"
                className="form-control mb-2"
                id={`characterCounts${index + 1}`}
                placeholder={`Characters ${index + 1}`}
                value={ch}
                onChange={handleChange}
              />
            ))}
          </div>

          <div className="form-group">
            <label>
              <b>Randomly Selected Talking Points </b>(The model will use one of
              these at random for each email)
            </label>
            {formData.randomTalkingPoints.map((rtp, index) => (
              <input
                key={index}
                type="text"
                className="form-control mb-2"
                id={`randomTalkingPoints${index + 1}`}
                placeholder={`Random Talking Point ${index + 1}`}
                value={rtp}
                onChange={handleChange}
              />
            ))}
          </div>

          <div className="form-group">
            <label>
              <b>Provide the greeting to start email</b> (The model will use one
              of these at random for each email)
            </label>
            <label>
              If they are left blank, the system will generate the greetings on
              its own
            </label>
            {formData.greetings.map((gr, index) => (
              <input
                key={index}
                type="text"
                className="form-control mb-2"
                id={`greetings${index + 1}`}
                placeholder={`Greeting ${index + 1}`}
                value={gr}
                onChange={handleChange}
              />
            ))}
          </div>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Please wait, generating emails..." : "Submit"}
          </button>
        </form>
        {results && (
          <div
            id="resultsContainer"
            className="mt-3"
            style={{ display: "block" }}
          >
            {results.emails.map((email, index) => (
              <div key={index} className="card mb-3">
                <div className="card-body">
                  <p>Subject: {email.subject}</p>
                  <p>Body: {email.body.replace(/\n/g, '<br>')}</p>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input approve-checkbox"
                      id={`approve-${index}`}
                      value={email.uuid} // Make sure the value is set to the UUID
                      onChange={(e) =>
                        handleCheckboxChange(email.uuid, e.target.checked)
                      } // Pass UUID and checked status
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`approve-${index}`}
                    >
                      Update in database
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {showPreApprove && (
          <>
            <label
              className="font-weight-bold mt-3"
              style={{ display: "block" }}
            >
              Click "Pre Approve" to pre approve the selected emails
            </label>
            <button
              type="button" // Change this from "submit" to "button"
              className="btn btn-primary"
              onClick={handlePreApprove} // Attach the handlePreApprove function
              disabled={preApproving} // Use preApproving state to control button's disabled status
            >
              {preApproving
                ? "Please wait, Preapproving emails..."
                : "Pre Approve"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export { CreateCampaign };
