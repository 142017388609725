import React, { useState, useEffect } from "react";
import { GoogleSignInButton } from './Login';
import { CreateCampaign } from './CreateCampaign';

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [isSessionValid, setIsSessionValid] = useState(false);

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessiontoken');
    const name = localStorage.getItem('name');

    if (sessionToken) {
      fetch('https://s37ovkglhf264t6e3pqsgidwtu0nalvo.lambda-url.us-east-1.on.aws/validate', {
        method: 'GET',  // Changed to GET as we're not sending a body anymore
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${sessionToken}`  // Send token in Authorization header
        }
      })
      .then(res => res.json())
      .then(data => {
        console.log('Validation Response:', data); // Debug
        if (data.valid) {
          setIsSessionValid(true);
          setIsLoggedIn(true);
          setUserName(name);
        } else {
          clearLocalStorage();
          setIsSessionValid(false);
        }
      })
      .catch(error => {
        console.error('Error validating session:', error);
        clearLocalStorage();
      });
    }
  }, []);

  const handleCredentialResponse = (response) => {
    console.log('Encoded JWT ID token: ', response.credential);
    setIsLoggedIn(true);

    // Post the token to the API endpoint
    fetch('https://s37ovkglhf264t6e3pqsgidwtu0nalvo.lambda-url.us-east-1.on.aws/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: response.credential })
    })
    .then(res => res.json())
    .then(data => {
      console.log('Login Response:', data); // Debug
      if (data.name && data.sessiontoken) {
        setIsLoggedIn(true);
        setUserName(data.name);
        localStorage.setItem('sessiontoken', data.sessiontoken);
        localStorage.setItem('name', data.name);
        localStorage.setItem('role', data.role || '');
        window.dispatchEvent(new CustomEvent('loginStatusChanged'));  // Notify components of the change
      } else {
        alert('You are not authorized to access this application.');
      }
    })
    .catch(error => {
      console.error('Error posting token:', error);
      alert('There was an error processing your login.');
    });
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('sessiontoken');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    setIsLoggedIn(false);
    setUserName('');
  };

  return (
    <div>
      <center>
      {!isLoggedIn ? (
        <div>
        <h1>Access to this application is restricted to authorized users only.</h1>
        <p>We do not have a process in place to request access. This option is coming soon.</p>
        <br />
        </div>
      ) : ('')
    }
        {isLoggedIn ? (
          <><h2>Hello, {userName}</h2><CreateCampaign /></>
        ) : (
          <GoogleSignInButton handleCredentialResponse={handleCredentialResponse} />
        )}
      </center>
    </div>
  );
};

export default Home;
