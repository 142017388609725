import React, { Component } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import Reports from "./Reports";
// Add any additional imports if you have specific logout components or others

export default class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: !!localStorage.getItem('sessiontoken')  // Checks if there is a session token
    };
  }

  componentDidMount() {
    window.addEventListener('loginStatusChanged', this.updateLoginStatus);
  }

  componentWillUnmount() {
    window.removeEventListener('loginStatusChanged', this.updateLoginStatus);
  }

  updateLoginStatus = () => {
    this.setState({ isLoggedIn: !!localStorage.getItem('sessiontoken') });
  }

  handleLogout = () => {
    const sessionToken = localStorage.getItem('sessiontoken');

    // Make the API call to the logout endpoint
    fetch('https://s37ovkglhf264t6e3pqsgidwtu0nalvo.lambda-url.us-east-1.on.aws/logout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionToken  // Assuming the API expects a token
        }
    })
    .then(response => response.json())
    .then(data => {
        console.log('Logout successful:', data);
        // Clear user session storage after successful API response
        localStorage.removeItem('sessiontoken');
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        // Optionally clear other stored data if necessary
        this.setState({ isLoggedIn: false });
        window.dispatchEvent(new CustomEvent('loginStatusChanged'));  // Notify components of the change
        // Redirect to login page or anywhere you want after logout
        window.location.href = '/home';
    })
    .catch(error => {
      localStorage.removeItem('sessiontoken');
      localStorage.removeItem('name');
      localStorage.removeItem('role');
      // Optionally clear other stored data if necessary
      this.setState({ isLoggedIn: false });
      window.dispatchEvent(new CustomEvent('loginStatusChanged'));  // Notify components of the change
      // Redirect to login page or anywhere you want after logout
      window.location.href = '/home';
      console.error('Logout error:', error);
        alert('Logout failed. Please try again.');
    });
};

  render() {
    const { isLoggedIn } = this.state;
    return (
      <Router>
        <Navbar bg="primary" data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="#home">Emails For Gaza</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/home">Home</Nav.Link>
              {isLoggedIn && <Nav.Link as={Link} to="/reports">Reports</Nav.Link>}
              {isLoggedIn && (
                <Nav.Link as={Link} to="/logout" onClick={this.handleLogout}>Logout</Nav.Link>
              )}
            </Nav>
          </Container>
        </Navbar>
        <Routes>
          <Route path="/home" element={<Home />} />
          {isLoggedIn && <Route path="/reports" element={<Reports />} />}
          <Route path="/logout" element={<Home />} /> {/* Change this if you have a specific Logout component */}
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    );
  }
}
